#background {
  width: 100%;
}

#qr-box,
#refresh-btn,
#name {
  position: absolute;
}

#name {
  font-size: 1.5em;
}
